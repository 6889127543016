#customCard {
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 10px;
    margin-right: 0px;
    /* background-image: linear-gradient(160deg, #bedef1 0%, #b3f1ea 100%); */
    cursor: pointer;
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    width: 253px;
    min-width: 200px;
    min-height: 70px;
}

#iconDash {
    width: 30px;
}

#fontColors {
    font-size: 22px;
    font-weight: 700;
    color: #546E7A;
}

#tittle {
    margin-left: 10px;
}

#fontColorsTitle {
    font-size: 15px;
    font-weight: 600;
    color: #888eac;
}

@media (max-width: 768px) {
    #customCard {
        width: 325px;
        height: 50px;
    }

    #fontColorsTitle {
        font-size: 8px;
    }
    
    #fontColors {
        font-size: 10px;
    }
}