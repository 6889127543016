.dashPage {
    display: flex;
    flex-flow: row wrap;
    flex-direction: column;
    flex-wrap: nowrap;
}

.cardDash {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
    padding: 5px;
    max-width: 780px;
} 

.graphicPagePie {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 4;
    padding: 5px;
}

.valuePerDay {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row-start: 1;
    grid-row-end: 2;
    padding: 5px;
    display: flex;
    flex-direction: row;
}

.cardsValues {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
    padding: 5px;
}

.categorieTable {
    padding: 10px;
}

.tableTotal {
    max-height: 320px;
    overflow: auto;
    display:inline-block;
    
}

.tableTotal td, .tableTotal th {
    min-width: 183px;
}

.cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

}

.analiticPage {
    flex-direction: row;
}

.buttonPaid {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.graphics {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: flex-start;
}

.outsByDay {
    display: block;
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 4;
    grid-row-end: 6;
    padding: 5px;
}

.finishingInstallments {
    font-size: 15px;
    font-weight: bold;
}

.analitics {
    display: flex;
    flex-flow: row wrap;
}

.modal-backdrop {
    backdrop-filter: blur(5px); /* Aplica o desfoque no fundo */
    background-color: rgba(0, 0, 0, 0.5) !important; /* Fundo escurecido */
}

.custom-icon {
    font-size: 2rem;
}
  

@media (max-width: 768px) {
    .graphics {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .valuePerDay {
        padding: 5px;
        display: flex;
        flex-direction: row;
    }

    .graphicPagePie {
        height: 300px;
    }

    .cardsValues {
        flex-direction: column;
    }

    .outsByDay {
        display: none !important; 
    }

    .card {
        margin-left: 0px;
    }

    .custom-icon {
        font-size: 1rem;
    }

    .finishingInstallments {
        font-size: 10px;
    }
}